<template>
  <div class="concentrators">
    <v-row>
      <v-col>
        <breadcrumb :items="breadcrumbs" />
      </v-col>
      <v-col class="text-right">
        <v-btn
          v-if="can('concentrator-create')"
          color="primary"
          class="mb-5"
          @click="showAddConcentratorDialog = true"
        >
          <v-icon
            left
            size="22"
          >
            {{ icons.mdiHomeGroup }}
          </v-icon>
          Add Concentrator
        </v-btn>
      </v-col>
    </v-row>
    <v-card>
      <v-card-title>
        <v-row
          justify="center"
          class="mb-0"
        >
          <v-col class="col-md-6 text-center">
            <search-input
              v-model="search"
              :filters="searchFilters"
              :active-filter="activeSearchFilter"
              @filter-change="onSearchFilterChange"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="concentrators"
        :loading="isConcentratorsLoading"
        hide-default-footer
      >
        <template v-slot:progress>
          <v-progress-linear
            indeterminate
            class="mt-2"
            rounded
          ></v-progress-linear>
          <p class="text-center mt-1">
            Loading...Please wait
          </p>
        </template>
        <template v-slot:body="{ items }">
          <tbody v-if="concentrators.length > 0">
            <tr
              v-for="concentrator in items"
              :key="concentrator.id"
            >
              <td>{{ concentrator.concentrator_id }}</td>
              <td>{{ concentrator.name }}</td>
              <td
                v-if="can('concentrator-edit') || can('concentrator-delete')"
                @click.stop
              >
                <v-tooltip
                  v-if="can('concentrator-edit')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      ma-2
                      v-bind="attrs"
                      text
                      icon
                      @click="editConcentrator(concentrator)"
                      v-on="on"
                    >
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiPencil }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip
                  v-if="can('concentrator-delete')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      ma-2
                      v-bind="attrs"
                      text
                      icon
                      @click="showDeleteConcentratorDialog(concentrator.id)"
                      v-on="on"
                    >
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiDelete }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-show="!isConcentratorsLoading">
              <td
                :colspan="headers.length"
                style="text-align: center"
              >
                No concentrators found
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-divider />
      <v-pagination
        v-model="pagination.page"
        class="mt-1"
        :length="pagination.pageCount"
        :total-visible="8"
        @input="onPageChange"
      ></v-pagination>
    </v-card>
    <add-concentrator-dialog
      :show-dialog="showAddConcentratorDialog"
      :concentrator="concentrator"
      @close="
        showAddConcentratorDialog = false
        concentrator = {}
      "
      @concentrator-added="getConcentrators()"
    />
    <confirm-dialog
      :show-dialog="showConfirmDeleteDialog"
      :is-agree-button-loading="isConfirmDeleteDialogButtonLoading"
      :agree-text="'Delete'"
      @cancel="showConfirmDeleteDialog = false"
      @agree="deleteConcentrator()"
    />
  </div>
</template>

<script>
import axios from 'axios'
import {
  mdiMagnify, mdiPencil, mdiDelete, mdiHomeGroup,
} from '@mdi/js'
import _ from 'lodash'
import hasPermission from '@/mixins/hasPermission'
import AddConcentratorDialog from '@/components/dialogs/AddConcentratorDialog.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import SearchInput from '@/components/partials/SearchInput.vue'

export default {
  components: {
    AddConcentratorDialog,
    ConfirmDialog,
    breadcrumb: BreadCrumb,
    SearchInput,
  },
  mixins: [hasPermission],
  data() {
    return {
      showAddConcentratorDialog: false,
      showConfirmDeleteDialog: false,
      isConfirmDeleteDialogButtonLoading: false,
      toDelete: null,
      sortBy: ['created_at'],
      sortDesc: [true],
      search: '',
      pagination: {
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
      },
      headers: [
        {
          text: 'ID',
          align: 'id',
          sortable: false,
        },
        {
          text: 'Name',
          align: 'name',
          sortable: false,
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      activeSearchFilter: 'name',
      searchFilters: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Id',
          value: 'concentrator_id',
        },
      ],
      icons: {
        mdiMagnify,
        mdiPencil,
        mdiDelete,
        mdiHomeGroup,
      },
      concentrators: [],
      isConcentratorsLoading: true,
      concentratorsUrl: 'concentrators',
      concentrator: {},
      concentratorId: this.$route.params.id,
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Stations',
          disabled: false,
          to: { name: 'concentrators' },
        },
      ],
    }
  },
  computed: {
    sortOrder() {
      return this.sortDesc[0] ? 'desc' : 'asc'
    },
    refreshTable() {
      return `${this.concentratorUrl}|${this.search}|${this.sortBy}|${this.sortDesc}`
    },
  },
  watch: {
    refreshTable() {
      this.getConcentrators()
    },
    activeSearchFilter() {
      if (this.search !== '') {
        this.getConcentrators()
      }
    },
  },
  mounted() {
    if (!(this.can('concentrator-edit') && this.can('concentrator-delete'))) {
      this.removeActionsHeader()
    }
    this.getConcentrators()
  },
  methods: {
    getConcentrators: _.debounce(function () {
      this.isConcentratorsLoading = true
      axios
        .get(
          `${this.concentratorsUrl}?paginate=true&sortBy=${this.sortBy[0]}&sortOrder=${this.sortOrder}&search=${this.search}&search_filter=${this.activeSearchFilter}&page=${this.pagination.page}`,
        )
        .then(response => {
          this.concentrators = response.data.data
          this.pagination.page = response.data.current_page
          this.pagination.pageCount = response.data.last_page
          this.pagination.itemsPerPage = response.data.per_page
          this.isConcentratorsLoading = false
        })
        .catch(error => {
          this.isConcentratorsLoading = false
          this.$notification.error(error.response.data.message)
        })
    }, 500),
    editConcentrator(concentrator) {
      this.concentrator = concentrator
      this.showAddConcentratorDialog = true
    },
    showDeleteConcentratorDialog(id) {
      this.showConfirmDeleteDialog = true
      this.toDelete = id
    },
    deleteConcentrator() {
      this.isConfirmDeleteDialogButtonLoading = true
      axios
        .delete(`concentrators/${this.toDelete}`)
        .then(() => {
          this.$notification.success('Concentrator deleted successfully')
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
          this.getConcentrators()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
        })
    },
    onPageChange() {
      this.getConcentrators()
    },
    onSearchFilterChange(filter) {
      this.activeSearchFilter = filter
    },
  },
}
</script>

<style>
  /* Similar styles if needed */
</style>
