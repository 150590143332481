<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ action === 'Save' ? 'Add' : 'Update' }} Concentrator</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="saveOrUpdate()"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.name"
                    label="Concentrator name"
                    placeholder="Some cool name"
                    :rules="[
                      v => !!v || 'Concentrator name is required',
                    ]"
                    required
                  ></v-text-field>
                  <small
                    v-show="form.errors.has('name')"
                    class="validation-error"
                  >
                    {{ form.errors.get('name') }}
                  </small>
                  <v-text-field
                    v-model="form.concentrator_id"
                    label="Concentrator ID"
                    placeholder="Unique identifier"
                    :rules="[
                      v => !!v || 'Concentrator ID is required',
                    ]"
                    :disabled="action === 'Update'"
                    required
                  ></v-text-field>
                  <small
                    v-show="form.errors.has('concentrator_id')"
                    class="validation-error"
                  >
                    {{ form.errors.get('concentrator_id') }}
                  </small>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'

export default {
  props: {
    showDialog: {
      type: Boolean,
    },
    concentrator: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: new Form({
      name: '',
      concentrator_id: '',
      id: '', // Assuming you'll have an 'id' field for updates
    }),
    action: 'Save',
  }),
  watch: {
    concentrator() {
      if (Object.keys(this.concentrator).length > 0) {
        this.form.update(this.concentrator)
        this.action = 'Update'
      } else {
        this.action = 'Save'
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close')
      this.$refs.form.reset()
    },
    saveOrUpdate() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.action === 'Save' ? this.saveConcentrator() : this.updateConcentrator()
      }
    },
    saveConcentrator() {
      this.form.post('concentrators')
        .then(() => {
          this.$notification.success('Concentrator added successfully')
          this.$emit('close')
          this.$emit('concentrator-added')
          this.$refs.form.reset()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        })
    },
    updateConcentrator() {
      this.form.put(`concentrators/${this.form.id}`)
        .then(() => {
          this.$notification.success('Concentrator updated successfully')
          this.$emit('close')
          this.$emit('concentrator-added')
          this.$refs.form.reset()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        })
    },
  },
}
</script>
